import Vue from 'vue/dist/vue.esm'
import axios from 'axios'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);



document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('vip-invoice-form-vue');
  if (element === null) {
    return
  }

  new Vue({
    el: "#vip-invoice-form-vue",
    data: function (){
      return {
        invoice: {},
        order:{
          'child_user_id': null
        },
        countries: [],
        childUsers: [],
        sameAsConsignee: true,
        inspectionRequiredCountryIds: [],
        minimalPrice: null,
        toPortOptions: [['Select', '']],
        loading: false,
        breakdown: false,
        lowestPercentageOriginal:null,//user_levelだけで判定した、一回目の支払い最低パーセンテージ
        lowestPercentage:null,//total_priceも関連して判定した、一回目の支払い最低パーセンテージ
        TotalPriceJudgementLine:null,//lowestPercentageを算出する際の分岐点
        ResettingLowestPercentage:null,// total_priceの値により変化するパーセンテージの値
        totalPriceChanged: false,
        firstPriceChanged: false
      }
    },
    mounted: function(){
      const container = this.$el;//<div id="admin-invoice-form-vue">

      // セキュリティのため、変数 invoice には Invoiceモデルの一部のカラムしか取得していません。
      // カラムを追加したい場合は、app/views/vip/invoices/_form.html.slim ファイルのto_jsonメソッドの引数を書き足してください。
      this.invoice = JSON.parse(container.getAttribute('data-invoice'));
      this.countries = JSON.parse(container.getAttribute('data-countries'));
      this.order = JSON.parse(container.getAttribute('data-order'));
      this.childUsers = JSON.parse(container.getAttribute('data-child-users'));

      const someFieldHasValue = [
        this.invoice['notify_party_username'],
        this.invoice['notify_party_address'],
        this.invoice['notify_party_tel'],
        this.invoice['notify_party_fax'],
        this.invoice['notify_party_email']
      ].some((value)=>Boolean(value))
      this.sameAsConsignee = !someFieldHasValue;

      const inspectionRequiredCountryIdsJson = container.getAttribute('data-inspection-required-countrie-ids');
      if(inspectionRequiredCountryIdsJson) {
        this.inspectionRequiredCountryIds = JSON.parse(inspectionRequiredCountryIdsJson);
      }

      //1回目の支払いの下限情報を取得 issue3933
      this.lowestPercentageOriginal = JSON.parse(container.getAttribute('data-lowest-percentage'));
      this.lowestPercentage = this.lowestPercentageOriginal;
      this.TotalPriceJudgementLine = JSON.parse(container.getAttribute('data-total-price-judgement-line'));
      this.ResettingLowestPercentage = JSON.parse(container.getAttribute('data-resetting-lowest-percentage'));

      if (this.hasBreakdown()){
        this.breakdown = true;
      }

      //空文字列にしなければ<option value>選択して下さい</option>が表示されない問題を修正
      Object.keys(this.invoice).forEach((key)=>{
        if (this.invoice[key] === null) {
          this.invoice[key] = '';
        }
      })

      this.getToPortOptions();
      this.getMinmalPrice();

      //invoice.idがない場合はnewと判断し、totalprice変更時の処理を実行する
      if (!this.invoice['id']){
        this.onChangeTotalPrice()
      }
    },
    watch:{
      'invoice.number_of_payments': function(){
        if (this.invoice['number_of_payments'] === 1) {
          ['first_price', 'second_price', 'second_due', 'third_price', 'third_due'].forEach((propertyName)=>{
            this.invoice[propertyName] = 0;
          })
        }
        if (this.invoice['number_of_payments'] === 2) {
          ['third_price', 'third_due'].forEach((propertyName)=>{
            this.invoice[propertyName] = 0;
          })
        }
      },
    },
    computed:{
      installmentPrice() {
        return this.invoice['first_price'] + this.invoice['second_price'] + this.invoice['third_price'];
      },
      cfsRequired(){
        const country = this.countries.find(country=>country[1] == this.invoice['to_country_id']);
        if (!country){
          return false
        }
        return ['KENYA', 'UGANDA'].includes(country[0]);
      },
      // zambia選択時にinspactionの選択を表示する
      isInspectionSelectable(){
        const country = this.countries.find(country=>country[1] == this.invoice['to_country_id']);
        if (!country){
          return false
        }
        return ['ZAMBIA'].includes(country[0]);
      },
      errors: function() {
        const errors = {};

        //可能な金額以下では発行しない。変更操作を行わない限りは、以下の制限処理は行わない
        // (価格制限機能リリース前のインボイスを編集する際に、金額以外の名前などの項目も更新できなくなるのを防ぐ措置。)
        if (this.minimalPrice && this.totalPriceChanged){
          if (this.invoice['total_price'] < this.minimalPrice){
            errors['total_price'] = `This invoice must be issued for the amount greater than $${this.minimalPrice -1}. `;
          }
        }

        //分割払いのバリデーション
        if (2 <= this.invoice['number_of_payments']) {
          if (this.invoice['total_price'] != this.installmentPrice) {
            const messageForInvalidSum = 'Sum of installment prices should equal to total price.';
            errors['first_price'] = messageForInvalidSum;
            errors['second_price'] = messageForInvalidSum;
            errors['third_price'] = messageForInvalidSum;
          }

          //1st payment validation
          if (this.invoice['first_price'] == 0){
            errors['first_price'] = 'Price is required.';
          }

          //2nd payment validation
          if (this.invoice['second_price'] == 0){
            errors['second_price'] = 'Price is required.';
          }

          // 分割払いの1回目の金額に対する下限制限 issue3536
          // 変更操作を行わない限りは、以下の制限処理は行わない
          // (価格制限機能リリース前のインボイスを編集する際に、金額以外の名前などの項目も更新できなくなるのを防ぐ措置。)
          if(this.firstPriceChanged){
            //1回目の支払いは、totalPriceの n% 以上である事
            if (this.invoice['first_price'] && this.invoice['first_price'] < parseInt(this.invoice['total_price'] * (this.lowestPercentage / 100.0))){
              errors['first_price']  = `This user must make a 1st payment of at least ${this.lowestPercentage}%.`;
            }
          }
        }

        return errors;
      },
      hasErrors: function(){
        return Object.keys(this.errors).length > 0;
      },
      disabled: function(){
        return this.loading || this.hasErrors;
      },
      selectedChildUser: function(){
        return this.childUsers.find(user => user.id === this.order['child_user_id']);
      }
    },
    methods: {
      clickUserIdButton: async function (){
        const user_id = this.invoice['user_id']
        if(user_id != ""){
          try{
            const result = await axios.get(`/admin/api/search_user/${this.invoice['user_id']}`)
            this.invoice['to_country_id'] = result.data['country_id']
            this.getToPortOptions()
            this.invoice['to_port_id'] = result.data['port_id']
            this.invoice['cfs'] = result.data['cfs_key']
          }catch (e){
            console.log(e)
          }
        }
      },
      onClickFillUserFields: async function(){
        await this.onClickFillConsignee();
        await this.onClickFillNotifyParty();
        await this.onClickFillDeliveryInformation();
      },
      onClickFillConsignee: async function(){
        const user = this.selectedChildUser
        if (!user) {
          return
        }
        this.invoice['username'] = user['username'];
        this.invoice['address'] = user['address'];
        this.invoice['tel'] = user['tel'];
        this.invoice['fax'] = user['fax'];
        this.invoice['email'] = user['email'];
      },
      onClickFillNotifyParty: async function(){
        const user = this.selectedChildUser
        if (!user) {
          return
        }
        this.sameAsConsignee = false;
        this.invoice['notify_party_username'] = user['notify_party_username'];
        this.invoice['notify_party_address'] = user['notify_party_address'];
        this.invoice['notify_party_tel'] = user['notify_party_tel'];
        this.invoice['notify_party_fax'] = user['notify_party_fax'];
        this.invoice['notify_party_email'] = user['notify_party_email'];
      },
      onClickFillDeliveryInformation: async function(){

        const user = this.selectedChildUser
        if (!user) {
          return
        }
        if (user['location_country_id'] != null) {
          this.invoice['to_country_id'] = user['location_country_id'];
        }else{
          this.invoice['to_country_id'] = user['country_id'];
        }
        await this.getToPortOptions()
        this.invoice['to_port_id'] = user['port_id'];
        this.invoice['final_destination_country_id'] = user['country_id'];
        this.invoice['cfs'] = user['cfs_key'];
        if(user['inspection'] == true){
          this.invoice['inspection'] = 'QISJ'
        }
      },
      onChangeSameAsConsignee: function () {
        if(this.sameAsConsignee){
          this.invoice['notify_party_username'] = '';
          this.invoice['notify_party_address'] = '';
          this.invoice['notify_party_tel'] = '';
          this.invoice['notify_party_fax'] = '';
          this.invoice['notify_party_email'] = '';
        }
      },

      onChangeTotalPrice: function(){
        this.totalPriceChanged = true;
        //total_priceの分岐点を超えた場合は、パーセンテージを変更
        if (this.TotalPriceJudgementLine && this.invoice['total_price'] >= this.TotalPriceJudgementLine){
          this.lowestPercentage = this.ResettingLowestPercentage//50%
        }else{
          this.lowestPercentage = this.lowestPercentageOriginal//100%
        }
      },
      onChangeToCountry: function (){
        this.invoice['to_port_id'] = '';

        if (!this.cfsRequired){
          this.invoice['cfs'] = null;
        }

        if (this.inspectionRequiredCountryIds.includes(this.invoice['to_country_id'])){
          this.invoice['inspection'] = 'QISJ';
        } else {
          this.invoice['inspection'] = '';
        }

        this.minimalPrice = null;
        this.getToPortOptions();
      },
      onChangeToPort: function(){
        this.getMinmalPrice();
      },
      onChangeInspection: function(){
        this.getMinmalPrice();
      },
      onChangeIncoterms: function(){
        this.getMinmalPrice();
      },
      onChangeNumberOfPayments: function(){
        this.getMinmalPrice();
      },
      onChangeFirstPrice: function () {
        this.firstPriceChanged = true;
      },
      onChangeBreakdown: function(){
        if (this.breakdown == false) {
          this.invoice['fob'] = 0;
          this.invoice['freight_cost'] = 0;
          this.invoice['inspection_cost'] = 0;
          this.invoice['insurance_cost'] = 0;
        }
      },
      hasBreakdown(){
        if (
          this.invoice['fob'] > 0 ||
          this.invoice['freight_cost'] > 0 ||
          this.invoice['inspection_cost'] > 0 ||
          this.invoice['insurance_cost'] > 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      div2(){
        const lowest_percentage_price = parseInt(this.invoice['total_price']  * (this.lowestPercentage / 100.0)); // 総額のn%金額 #issue3536
        const remainder = this.invoice['total_price']%2;
        const equal_part = (this.invoice['total_price']-remainder)/2;

        this.invoice['first_price'] = equal_part+remainder;
        this.invoice['second_price'] = equal_part;
        this.invoice['third_price'] = 0;
        this.invoice['third_due'] = 0;

        if (this.invoice['first_price'] < lowest_percentage_price) {
          this.calculateLowestPercentagePrice_div(lowest_percentage_price, 2)
        }
      },
      div3(){
        const lowest_percentage_price = parseInt(this.invoice['total_price'] * (this.lowestPercentage / 100.0)); // 総額のn%金額 #issue3536

        const remainder = this.invoice['total_price']%3;
        const equal_part = (this.invoice['total_price']-remainder)/3;

        this.invoice['first_price'] = equal_part+remainder;
        this.invoice['second_price'] = equal_part;
        this.invoice['third_price'] = equal_part;

        if (this.invoice['first_price'] < lowest_percentage_price) {
          this.calculateLowestPercentagePrice_div(lowest_percentage_price, 3)
        }
      },
      calculateLowestPercentagePrice_div(lowest_percentage_price, number_of_payments){
        const totalPrice_minus_after_thirty_percent = this.invoice['total_price'] - lowest_percentage_price; // 総額の30%を引いた残りの金額 issue#3286
        this.invoice['first_price'] = lowest_percentage_price; // 1回目の金額は必ず総額の30%を設定 issue#3286

        const number = number_of_payments - 1; // 1回目の金額を設定したため、「-1」
        const remainder = totalPrice_minus_after_thirty_percent % number;
        const equal_part = (totalPrice_minus_after_thirty_percent - remainder) / number;

        this.invoice['first_price'] += remainder;

        // div2とdiv3でそれそれ設定が違うため
        if (number_of_payments == 2) {
          this.invoice['second_price'] = equal_part;
          this.invoice['third_price'] = 0;
          this.invoice['third_due'] = 0;
        } else if (number_of_payments == 3) {
          this.invoice['second_price'] = equal_part;
          this.invoice['third_price'] = equal_part;
        }
      },
      getToPortOptions: async function (){
        const ports = []
        ports.push(['Select', ''])

        if(!this.invoice['to_country_id']){
          return;
        }
        this.loading = true;
        try{
          const result = await axios.get(`/api/port/${this.invoice['to_country_id']}`);
          this.toPortOptions = ports.concat(result.data);
        }catch (e){
          console.log(e)
        }
        this.loading = false;
      },
      getMinmalPrice: async function(){
        this.minimalPrice = null;

        if (!this.invoice['to_port_id']){
          return;
        }

        this.loading = true;
        try{
          const result = await axios.get('/vip/invoices/api/minimal_price/', {
            params: {
              'car_history_id': this.invoice['car_history_id'],
              'to_port_id': this.invoice['to_port_id'],
              'inspection': this.invoice['inspection'],
              'incoterms': this.invoice['incoterms'],
              'number_of_payments': this.invoice['number_of_payments']
            }
          })
          this.minimalPrice = result.data['price'];
        }catch (error) {
          console.log(error);
        }
        this.loading = false;
      },
    },
  })

});